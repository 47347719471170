<template>
  <div>
    <div v-if="!isLoading">
      <div class="uk-flex uk-flex-wrap">
        <div class="uk-card uk-card-primary uk-padding-small uk-border-rounded">
          <div class="uk-text-large">
            {{ user.first_name }} {{ user.last_name }}
          </div>
          <div v-if="user.business">
            <span class="uk-text-bold">Empresa:</span>
            {{ user.business.name }}
          </div>
          <div>
            <span class="uk-text-bold">Puesto:</span>
            {{ user.job_title }}
          </div>
          <div>
            <span class="uk-text-bold">Teléfono:</span>
            {{ user.phone }}
          </div>
          <div>
            <span class="uk-text-bold">Celular:</span>
            {{ user.mobile_phone }}
          </div>
          <div>
            <span class="uk-text-bold">Tipo:</span>
            {{ user.type.name }}
          </div>
          <div>
            <span class="uk-text-bold">Correo:</span>
            {{ user.email }}
          </div>
        </div>
        <div class="uk-padding-small uk-width-expand">
          <div class="uk-flex uk-flex-between">
            <div class="uk-text-large">Productos en carrito</div>
            <button
              v-if="user.pending_quotables.length !== 0"
              :disabled="isLoading"
              @click="generateQuote"
              class="uk-button uk-button-primary uk-border-rounded"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else>Generar cotización</span>
            </button>
          </div>
          <div v-if="user.pending_quotables.length !== 0">
            <table
              class="
                uk-table
                uk-table-divider
                uk-table-hover
                uk-table-middle
                uk-visible@s
              "
            >
              <thead>
                <th width="10%" class="uk-text-center">Cantidad</th>
                <th width="15%" class="uk-text-center">Imágen</th>
                <th width="10%">Producto</th>
                <th width="10%" class="uk-text-center">Caras de impresión</th>
                <th width="10%" class="uk-text-center">Colores de impresión</th>
                <th width="10%">Notas</th>
                <th width="10%" class="uk-text-right">Precio unitario</th>
                <th width="10%" class="uk-text-right">Precio total</th>
                <th width="15%" class="uk-text-right"></th>
              </thead>
              <tbody>
                <tr
                  v-for="userQuotable in user.pending_quotables"
                  :key="userQuotable.id"
                >
                  <td class="uk-text-center">
                    {{ userQuotable.quantity }}
                  </td>
                  <td class="uk-text-center">
                    <img
                      width="100%"
                      :src="
                        userQuotable.quotable.filled_fields.filter(
                          (filledField) => filledField.field.slug === 'image'
                        )[0].value
                      "
                      alt
                    />
                  </td>
                  <td>
                    {{
                      userQuotable.quotable_type === "App\\Models\\Product"
                        ? userQuotable.quotable.name
                        : userQuotable.quotable_type ===
                          "App\\Models\\ProductVariation"
                        ? userQuotable.quotable.product.name
                        : ""
                    }}
                  </td>
                  <td class="uk-text-center">
                    {{ userQuotable.faces }}
                  </td>
                  <td class="uk-text-center">
                    {{ userQuotable.colors }}
                  </td>
                  <td>{{ userQuotable.notes }}</td>
                  <td class="uk-text-right">
                    {{ toCurrency(userQuotable.price) }}
                  </td>
                  <td class="uk-text-right">
                    {{ toCurrency(userQuotable.price * userQuotable.quantity) }}
                  </td>
                  <td class="uk-text-right">
                    <div class="uk-text-right">
                      <div
                        class="
                          uk-button-group uk-border-rounded uk-overflow-hidden
                        "
                      >
                        <button
                          class="uk-button uk-button-default"
                          uk-icon="pencil"
                          href="#edit-userQuotable-modal"
                          uk-toggle
                          @click="setEditMode(userQuotable)"
                        ></button>
                        <button
                          class="uk-button uk-button-default"
                          uk-icon="trash"
                          href="#delete-userQuotable-modal"
                          uk-toggle
                          @click="setEditMode(userQuotable)"
                        ></button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul class="uk-list uk-list-divider uk-hidden@s">
              <li
                v-for="userQuotable in user.pending_quotables"
                :key="userQuotable.id"
              >
                <div class="uk-text-center">
                  <img
                    width="50%"
                    :src="
                      userQuotable.quotable.filled_fields.filter(
                        (filledField) => filledField.field.slug === 'image'
                      )[0].value
                    "
                    alt
                  />
                  <div>Cantidad: {{ userQuotable.quantity }}</div>
                  <div>{{ userQuotable.price }}</div>
                  <div>{{ toCurrency(0) }}</div>
                  <div
                    class="
                      uk-margin-small-top
                      uk-button-group
                      uk-border-rounded
                      uk-overflow-hidden
                    "
                  >
                    <button
                      class="uk-button uk-button-default"
                      uk-icon="pencil"
                      href="#edit-userQuotable-modal"
                      uk-toggle
                      @click="setEditMode(userQuotable)"
                    ></button>
                    <button
                      class="uk-button uk-button-default"
                      uk-icon="trash"
                      href="#delete-userQuotable-modal"
                      uk-toggle
                      @click="setEditMode(userQuotable)"
                    ></button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="uk-text-large uk-margin-top">
            Historial de actividades
          </div>
          <table
            class="
              uk-table
              uk-table-striped
              uk-table-middle
              uk-table-hover
              uk-table-divider
            "
          >
            <thead>
              <tr>
                <th>Acción</th>
                <th>Producto</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="log in user.logs" :key="log.id">
                <td>{{ log.description }}</td>
                <td>
                  <router-link :to="'/store/' + log.subject.id">{{
                    log.subject.name
                  }}</router-link>
                </td>
                <td>{{ formatDatetime(log.created_at) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else class="uk-text-center">
      <div uk-spinner></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsersShow",

  data() {
    return {
      user: null,
      isLoading: true,
    };
  },

  mounted() {
    this.fetchUser();
  },

  methods: {
    fetchUser() {
      this.isLoading = true;
      this.axios
        .get("/users/" + this.$route.params.id)
        .then(({ data }) => {
          this.user = data.user;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    generateQuote() {
      this.isLoading = true;
      this.axios
        .get("/quotes/generate", {
          params: {
            user_id: this.user.id,
          },
        })
        .then(({ data: { pastQuotes } }) => {
          this.userQuotables = [];
          this.pastQuotes = pastQuotes;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
